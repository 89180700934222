var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "auth" }, [
      _c(
        "div",
        { staticClass: "auth__container" },
        [
          _c("div", { staticClass: "auth-bg" }, [
            _c("div", { staticClass: "auth-bg__text" }, [
              _vm.activeEvent
                ? _c("span", [_vm._v(_vm._s(_vm.activeEvent.title))])
                : _vm._e()
            ])
          ]),
          !_vm.hash
            ? _c(
                "v-card",
                { staticClass: "auth-form", attrs: { rounded: "xl" } },
                [
                  _c("c-img", {
                    staticClass: "circle-small auth-form__circle-2",
                    attrs: {
                      src: "assets/img/theme/modal-bottom-element.png",
                      alt: "circle1"
                    }
                  }),
                  _c("c-img", {
                    staticClass: "circle-big auth-form__circle-1",
                    attrs: {
                      src: "assets/img/theme/modal-top-element.png",
                      alt: "circle2"
                    }
                  }),
                  _c("v-card-title", { staticClass: "auth-form__header" }, [
                    _c("div", { staticClass: "auth-form__header-inner" }, [
                      _c("div", { staticClass: "auth-form__header-top" }, [
                        _vm.activeEvent
                          ? _c(
                              "div",
                              { staticClass: "auth-form__header-caption" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("welcome to foresight winter")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "auth-form__logo" },
                          [
                            _c("c-img", {
                              attrs: {
                                src:
                                  "assets/img/" +
                                  _vm.themePath +
                                  "/event-logo-light.png",
                                alt: "logo"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm.currentEventStaticData
                        ? _c(
                            "div",
                            { staticClass: "auth-form__header-description" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("foresight winter will take")) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "auth-form__form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.onSubmit($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "auth-form__body" },
                        [
                          _c("v-text-field", {
                            staticClass: "auth-form__field",
                            attrs: {
                              outlined: "",
                              name: "login",
                              label: _vm.$t("login"),
                              rules: [_vm.requiredRule]
                            },
                            model: {
                              value: _vm.username,
                              callback: function($$v) {
                                _vm.username = $$v
                              },
                              expression: "username"
                            }
                          }),
                          _c("v-text-field", {
                            staticClass: "auth-form__field",
                            attrs: {
                              outlined: "",
                              name: "password",
                              label: _vm.$t("password"),
                              rules: [_vm.requiredRule],
                              "append-icon": _vm.showPassword
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.showPassword ? "text" : "password"
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.showPassword = !_vm.showPassword
                              }
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "auth-form__bottom" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "auth-form__submit-btn",
                              attrs: {
                                "min-height": "60",
                                "min-width": "220",
                                type: "submit",
                                disabled: _vm.isLoading,
                                loading: _vm.isLoading
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("submitButton")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-overlay",
            { attrs: { value: _vm.isLoading } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }