import apiClient from '@/services/api/apiClient'
import { AUTH_PREFIX } from '@/constants/apiPrefixes'

const AUTH_PATH = process.env.VUE_APP_AUTH_PATH

export const login = ({ username, password }) => apiClient.post(`${AUTH_PREFIX}/${AUTH_PATH}/auth/login`, {
  login: username,
  password
})

export const loginByHash = (hash) => apiClient.post(`${AUTH_PREFIX}/user/auth/login/${hash}`)
