import Vuex from 'vuex'
import * as apiAuth from '@/services/api/auth'
import * as authService from '@/services/auth'
import userService from '@/services/user'

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  getters: {
    deserialize: () => (data) => ({
      access: {
        accessToken: data.token,
        centrifugoToken: data.centrifugo_token,
        roles: data.roles?.map(role => role.code) ?? [],
        permissions: data.permissions?.map(perm => ({
          operation: perm.operation.code,
          resource: perm.resource.code
        })) ?? []
      },
      user: userService.store.getters.deserialize(data)
    })
  },
  actions: {
    async login ({ getters }, { credits, hash }) {
      if (!credits && !hash) throw new Error('credits or hash required')

      const { data } = hash ? await apiAuth.loginByHash(hash) : await apiAuth.login(credits)
      const deserialized = getters.deserialize(data)
      authService.setAccess(deserialized.access)
      await userService.initUser(deserialized.user)
      return deserialized
    }
  }
})
